<template>
  <div class="device-search">
    <p class="device-search-title">{{ title }}</p>
    <div class="device-search-middle" :class="{ active: isInputActive }">
      <input class="device-search-middle-input" type="text" ref="input" v-model="value" @blur="onInputBlur"
        @click="onInputClick" />
    </div>
    <button class="device-search-button" @click="onSearchClick">搜索</button>
  </div>
</template>

<script>
export default {

  model: {
    prop: 'keywords',
    event: 'onSearchClick'

  },
  
  props: {
    title: String,
    keywords: String,
  },

  data: function () {
    return {
      value: "",
      isInputActive: false,
    };
  },

  mounted() {
    this.value = this.keywords
  },

  watch: {
    keywords: function (newValue) {
      this.value = newValue;
    },
  },

  methods: {
    onInputBlur: function () {
      this.isInputActive = false;
    },

    onInputClick: function () {
      this.isInputActive = true;
    },

    onSearchClick: function () {
      this.isInputActive = false;
      this.$refs.input.blur();
      this.$emit("onSearchClick", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.device-search {
  display: flex;
  align-items: center;

  &-title {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(22);
    margin-right: rem(10);
  }

  &-middle {
    background-color: white;
    border: rem(1) solid #dcdfe6;
    border-top-left-radius: rem(4);
    border-bottom-left-radius: rem(4);
    width: rem(147);
    height: rem(32);
    box-sizing: border-box;
    padding-left: rem(15);
    padding-right: rem(5);
    display: flex;
    align-items: center;

    &-input {
      color: rgba($color: black, $alpha: 0.65);
      font-size: rem(14);
      border: none; // 去除未选中状态边框
      outline: none; // 去除选中状态边框
      width: 100%;
    }

    &-input::-webkit-input-placeholder {
      color: #909399;
      font-size: rem(14);
    }
  }

  &-button {
    color: white;
    background-color: #325FAC;
    font-size: rem(14);
    border-top-right-radius: rem(4);
    border-bottom-right-radius: rem(4);
    width: rem(53);
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.active {
  border: 1px solid #325FAC;
}
</style>
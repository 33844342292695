<template>
  <div
    class="list-cell"
    :class="{ selected: isSelected }"
    @click="onSelectClick"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isSelected: Boolean,
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick");
    },
  },
};
</script>

<style lang="scss" scoped>


.list-cell {
  background-color: white;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  min-height: 54px;
  box-sizing: border-box;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: #f7f7f7;
  }
}

.selected {
  background-color: #e6f7ff;

  &:hover {
    background-color: #e6f7ff;
  }
}
</style>
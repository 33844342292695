<template>
  <div class="list-title-bar">
    <div
      class="list-title-bar-unit"
      v-for="(item, index) of titleArray"
      :key="index"
      :style="{ width: item.percent }"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleArray: Array,
  },
};
</script>

<style lang="scss" scoped>


.list-title-bar {
  background-color: #fafafa;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  position: relative;
  display: flex;

  &-unit {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    width: 8%;
  }

  .gender {
    width: 8%;
  }

  .age {
    width: 8%;
  }

  .phone-number {
    width: 13%;
  }

  .monitor-status {
    width: 18%;
  }

  .organization {
    width: 20%;
  }

  .operation {
    width: 25%;
  }
}
</style>
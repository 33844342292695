<template>
  <div class="bar-picker">
    <button
      class="bar-picker-item"
      v-for="(item, index) of itemArray"
      :key="index"
      :class="{ active: currentSelectedIndex == index }"
      @click="onItemSelected(index)"
    >
      {{ item }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    initialSelectedIndex: {
      type: Number,
      default: 0,
    },

    itemArray: Array,
  },

  data() {
    return {
      currentSelectedIndex: 0,
    };
  },

  watch: {
    initialSelectedIndex(value) {
      this.currentSelectedIndex = value;
    },
  },

  created() {
    this.currentSelectedIndex = this.initialSelectedIndex;
  },

  methods: {
    onItemSelected(index) {
      this.currentSelectedIndex = index;
      this.$emit("onItemSelected", this.currentSelectedIndex);
    },
  },
};
</script>

<style lang="scss" scoped>


.bar-picker {
  display: flex;

  &-item {
    color: #666666;
    font-size: rem(14);
    border-bottom: rem(2) solid;
    border-bottom-color: #e8e8e8;
    box-sizing: border-box;
    padding: 0 rem(15);
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active {
    color: #325FAC;
    border-bottom-color: #325FAC;
  }
}
</style>

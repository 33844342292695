<template>
  <div class="filter-bar z-index-2">
    <div class="filter-bar-left">
      <bar-picker :initialSelectedIndex="currentIndex" :itemArray="itemArray" @onItemSelected="onItemSelected">
      </bar-picker>
      <button v-if="buttonTitle" class="filter-bar-left-button" @click="onButtonClick">
        {{ buttonTitle }}
      </button>
      <slot></slot>
    </div>
    <div class="filter-bar-right">
      <el-date-picker v-if="hasDatePicker" class="filter-bar-right-date-picker" size="mini" v-model="duration"
        type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']" @change="onDurationSelected">
      </el-date-picker>
      <keyword-search v-if="keywordTitle" class="filter-bar-right-keyword-search" :title="keywordTitle"
        v-model="keywordTemp" @onSearchClick="onSearchClick"></keyword-search>
    </div>
  </div>
</template>

<script>
import BarPicker from "@c/operation-log/common/bar-picker.vue";
import KeywordSearch from "@c/operation-log/common/filter-bar/keyword-search.vue";
import { Select, Option, Input, Button } from "element-ui";

export default {
  components: {
    BarPicker,
    KeywordSearch,
    ElSelect: Select,
    ELOption: Option,
    ElInput: Input,
    ElButton: Button,
  },

  model: {
    prop: 'keyword',
    event: 'onSearchClick'
  },

  props: {
    initialSelectedIndex: {
      type: Number,
      default: 0,
    },
    itemArray: Array,
    //有否按钮
    buttonTitle: {
      type: String,
      default: null,
    },
    //有否日期选择器
    hasDatePicker: {
      type: Boolean,
      default: false,
    },
    initDuration: {
      type: Array,
      required: false,
    },
    //有否关键词搜索栏
    keywordTitle: {
      type: String,
      default: null,
    },
    keyword: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      currentIndex: 0,
      keywordTemp: "",
      duration: undefined
    };
  },

  watch: {
    initialSelectedIndex(value) {
      this.currentIndex = value;
    },

    initDuration(value) {
      this.duration = value;
    },

    keyword(value) {
      this.keywordTemp = value
    }

  },

  created() {
    this.currentIndex = this.initialSelectedIndex;
    this.keywordTemp = this.keyword
  },

  methods: {
    onItemSelected(index) {
      this.currentIndex = index;
      this.$emit("onItemSelected", this.currentIndex);
    },

    onSearchClick(value) {
      this.$emit("onSearchClick", value)
    },

    onButtonClick() {
      this.$emit("onButtonClick");
    },

    onDurationSelected() {
      this.$emit("onDurationSelected", this.duration);
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  padding: rem(20) rem(0);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;

    &-item {
      color: #666666;
      font-size: rem(14);
      border-bottom: rem(2) solid;
      border-bottom-color: #e8e8e8;
      width: rem(100);
      height: rem(32);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active {
      color: #325FAC;
      border-bottom-color: #325FAC;
    }

    &-button {
      color: white;
      background-color: #325FAC;
      font-size: rem(14);
      border-radius: rem(4);
      width: rem(88);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: rem(74);
    }
  }

  &-right {
    display: flex;
    align-items: center;

    &-select {
      margin-right: 10px;
    }

    &-keyword-search {
      margin-left: 10px;
    }
  }
}
</style>

<style lang="scss">
.el-range-editor--mini .el-range__icon{
  margin-top: rem(5);
}
.el-range-editor--mini.el-input__inner {
  border: transparent;
}

.el-range-editor--mini .el-range-input {
  font-size: rem(16);
  margin-top: rem(2);
}

.el-range-editor--mini .el-range-separator {
  font-size: rem(16);
  margin-top: rem(2);

}

</style>
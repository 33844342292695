<template>
  <div class="cell-unit" @click="onClick">
    <img v-if="icon" class="cell-unit-icon" :src="icon" />
    <p class="cell-unit-title" :style="{ color: color }">
      {{ title }}
      {{ buttonTitle == "" ? "" : "【" }}
      <span :class="[isButtonEnabled ? 'cell-unit-button-title' : 'cell-unit-button-title-disabled']"
        @click="onButtonClick">{{ buttonTitle == "" ? "" : `${buttonTitle}` }}
      </span>
      {{ buttonTitle == "" ? "" : "】" }}
    </p>
    <slot></slot>

  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },

    title: String,

    color: {
      type: String,
      default: "#666666",
    },

    buttonTitle: {
      type: String,
      default: "",
    },

    isButtonEnabled: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onClick: function () {
      this.$emit("onClick");
    },

    onButtonClick() {
      this.$emit("onButtonClick");
    },
  },
};
</script>

<style lang="scss" scoped>


.cell-unit {
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    width: rem(16);
    margin-right: rem(3);
  }

  &-title {
    font-size: rem(14);
    line-height: rem(22);
  }

  &-button-title {
    color: #325FAC;
    border-bottom: rem(1) solid #325FAC;
    cursor: pointer;
  }

  &-button-title-disabled {
    color: #c1c1c1;
    border-bottom: rem(1) solid #c1c1c1;
  }
}
</style>